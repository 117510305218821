.homePage {
    .mainImg {
        width: 100%;
        display: block;
    }
    .footer {
        font-size: 1.2rem;
        padding: 2rem 0px;
        color: #000;
        text-align: center;
        img {
            vertical-align: middle;
        }
    }
    .footer a {
        color: #000;
        text-decoration: none;
    }
}
.braun {
    min-height: 100%;
    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .logoImg {
            width: 12.8571rem;
            margin: 3.4286rem auto 2.7143rem;
        }
        .bottomBox {
            width: 80%;
            margin: 4rem auto;
            display: flex;
            justify-content: space-between;
            .item {
                .itemTop {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.71429rem;
                    font-size: 1.28571rem;
                    font-weight: bold;
                    .icon {
                        width: 2.8571rem;
                        height: 2.8571rem;
                        margin-right: 0.2rem;
                    }
                }
                .itemBottom, .itemBottom a {
                    font-size: 1rem;
                    color: #666;
                }
            }
        }
    }
}
.downy {
    .container {
        position: relative;
        .mainBox {
            width: 100%;
            position: absolute;
            display: flex;
            flex-direction: column;
            .logoImg {
                width: 11%;
                padding-top: 5.5%;
                padding-left: 4%;
            }
            .titleImg {
                padding: 10% 0 0 0;
                width: 77%;
                display: block;
                margin: 0 auto;
            }
            .qrCodeImg {
                width: 30%;
                display: block;
                margin: 0 auto;
                padding: 4% 0 0 0;
            }
        }
        .bgImg {
            width: 100%;
            display: block;
        }
    }
}
.pampers {
    background-color: #BDEBE9;
    .container {
        padding-top: 8rem;
        padding-bottom: 12rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .logoImg {
            width: 15rem;
        }
        .menuImg {
            width: 40.5rem;
        }
        .qrCodeImg {
            margin-top: 3rem;
            width: 25rem;
        }
    }
    .footer {
        background-color: #fff;
        color: #025552;
        .black {
            color: rgb(153, 153, 153)
        }
    }
    .footer a {
        color: #025552;
    }
}
.homePages{
    width: 800px;
    margin: 0 auto;
    text-align: center;
}
h2{
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo{
    width: 180px;
    vertical-align: text-bottom;
    margin-right: 5px;
}
.titTable{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-width: 0 0 1px 0;
    border-bottom: 1px solid #cbcbcb;
    font-size: 12px;
    font-weight: bold;
    background-color: #e0e0e0;
    color: #000;
    text-align: left;
    vertical-align: bottom;
    padding: 6px 0;
}
.titTables{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-width: 0 1px 1px 1px;
    border-left: 1px solid #cbcbcb;
    border-right: 1px solid #cbcbcb;
    border-bottom: 1px solid #cbcbcb;
    font-size: 12px;
    color: #000;
    text-align: left;
    vertical-align: bottom;
    padding: 6px 0;
}
.tab1{
    width: 150px;
    text-align: left;
    margin-left: 10px;
}
.tab1s{
    width: 40px;
    text-align: left;
    margin-left: 10px;
}
.tab2{
    width: 66px;
    text-align: left;
    margin-left: 10px;
}
.tab2s{
    width: 40px;
    text-align: left;
    margin-left: 10px;
}
.tab3{
    width: 66px; text-align: left;
    margin-left: 10px;
}
.tab3s{
    width: 150px; text-align: left;
    margin-left: 10px;
}
.tab4{
    width: 230px;
    text-align: left;
    margin-left: 10px;
}
.tab5{
    width: 100px;
    margin-left: 10px;
}
.tab5s{
    width: 150px;
    margin-left: 10px;
}
.footers {
    display: flex;
    font-size: 12px;
    margin: 20px auto;
    width: 800px;
}
.footers .footer_logos {
    width: 140px;
    margin-right: 10px;
}
.footers .footer_logos, .footers .footer_descs {
    zoom: 1;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}
#clause {
    margin: 20px;
    text-align:left;
}

#clause p {
    margin-top: 20px;
    line-height: 30px;
    font-size: 16px;
    color: #000000;
}

#clause p a {
    font-size: 16px;
    color: blue;
}

#clause h2 {
    margin-top: 20px;
    font-weight: bold;
    line-height: 30px;
    font-size: 16px;
    color: #000000;
}
